import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

function welcome() {
   return (
      <div className="pt-0 md:pt-48">
         <div className="mx-auto max-w-7xl">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
               <div className="relative">
                  <StaticImage
                     src="../images/portrait.png"
                     alt="Photo of the doctor."
                     placeholder="blurred"
                     quality={100}
                     aspectRatio={1 / 1}
                     className="rounded-none md:rounded-xl z-20 shadow-2xl "
                  />
                  <div className="bg-cyan-900/50 w-5 h-5/6 absolute top-0 -left-3 z-10 rounded-xl mt-12"></div>
               </div>
               <article className="prose lg:prose-md flex items-center flex-col px-8">
                  <h2 className="text-cyan-900 font-light">Καλωσήρθατε στην ιστοσελίδα του Χειρουργού Ουρολόγου, Ανδρολόγου Σαρδαρίδη Φιλάρετου!</h2>
                  <p>
                     Ο <strong>κος Σαρδαρίδης Φιλάρετος</strong> Χειρουργός Ουρολόγος, Ανδρολόγος εξειδικεύεται στην διάγνωση και θεραπεία  ουρολογικών παθήσεων.
                     Το ουρολογικό ιατρείο βρίσκεται στην οδό Θ. Καρυπίδη 4, στην Πολίχνη Θεσσαλονίκης και είναι άρτια εξοπλισμένο με ιατρικά μηχανήματα σύγχρονης τεχνολογίας.
                  </p>
                  <p>
                     Ο Ιατρός συνεργάζεται με άλλους ειδικούς, όπως καρδιολόγους και νευρολόγους, για να παρέχουν πλήρη θεραπεία στους ασθενείς μας, καθώς και με ιδιωτικές κλινικές για ενδεχόμενη
                     χειρουργική αντιμετώπιση των ασθενών (Άγιος Λουκάς,
                     Βιοκλινική - πρώην Γαληνός). Επίσης, είναι δυνατή η επίσκεψη κατ' οίκον με φορητό υπέρηχο.
                  </p>
                  <p>
                     Στην ιστοσελίδα μας θα βρείτε πληροφορίες για τις υπηρεσίες που προσφέρουμε, τις παθήσεις που αντιμετωπίζουμε και τους τρόπους με τους οποίους μπορείτε να επικοινωνήσετε μαζί μας για οποιαδήποτε ερώτηση ή ανησυχίες που μπορεί να έχετε.
                  </p>
                  <p className='italic'>Σας ευχαριστούμε που επισκεφθήκατε την ιστοσελίδα μας</p>
               </article>
            </div>
         </div>
      </div>
   )
}

export default welcome
