import * as React from 'react'

import PhoneIcon from '../images/icons/Phone'
import ClockIcon from '../images/icons/Clock'
import LocationIcon from '../images/icons/Location'

function infoBox() {
   return (
      <div className="ml-auto max-w-6xl z-50 relative md:-mt-44">
         <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="bg-cyan-900/90 backdrop-blur-md h-[20rem] md:px-12 px-6 md:py-12 py-8 md:rounded-l-xl">
               <div className="grid grid-cols-1 md:grid-cols-2 md:divide-x md:divide-cyan-700 md:space-y-0 space-y-8">
                  <div className="flex items-start justify-start">
                     <span className="inline-flex items-center justify-center p-2 bg-orange-500 rounded-md shadow-lg">
                        <PhoneIcon size={20} color="white" />
                     </span>
                     <div className="ml-4">
                        <h3 className="text-white text-base font-bold tracking-tight">
                           Τηλέφωνο
                        </h3>
                        <p className="text-white mt-2">Γραφείο: 2310 603 356</p>
                        <p className="text-white">Κινητό: 6947 807 229</p>
                     </div>
                  </div>
                  <div className="flex items-start justify-start md:pl-6">
                     <span className="inline-flex items-center justify-center p-2 bg-orange-500 rounded-md shadow-lg">
                        <LocationIcon size={20} color="white" />
                     </span>
                     <div className="ml-4">
                        <h3 className="text-white text-base font-bold tracking-tight">
                           Διεύθυνση
                        </h3>
                        <p className="text-white mt-2">
                           Θ. Καρυπίδη 4, Πολίχνη 56533, Θεσσαλονίκη
                        </p>
                     </div>
                  </div>
               </div>
            </div>

            <div className="bg-cyan-900 md:px-12 px-6 md:py-12 py-8">
               <div className="flex mb-3 items-center text-sky-50">
                  <ClockIcon size={20} color="white" />
                  <h3 className="ml-3 font-bold">Ωράριο Λειτουργίας</h3>
               </div>
               <div className="grid grid-cols-1 divide-y divide-cyan-700">
                  <div className="flex items-center text-white p-4">
                     <span>Δευτέρα - Τρίτη</span>
                     <span className="ml-auto">
                        09:30 - 14:00 - 17:30 - 20:30
                     </span>
                  </div>
                  <div className="flex items-center text-white p-4">
                     <span>Τετάρτη - Παρασκευή</span>
                     <span className="ml-auto">17:30 - 20:30</span>
                  </div>
                  <div className="flex text-white p-4">
                     <span>Σάββατο - Κυριακή</span>
                     <span className="ml-auto">Κλειστά</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default infoBox
