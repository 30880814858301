import * as React from 'react'

import Layout from '../components/layout'
import Hero from '../components/Hero'
import InfoBox from '../components/infoBox'
import Welcome from '../components/welcome'
import FrontServices from '../components/frontServices'
import FrontAppointment from '../components/frontAppointment'
import FrontProblems from '../components/frontProblems'
import Map from '../components/common/map'

const IndexPage = () => {
   return (
      <Layout>
         <Hero />
         <InfoBox />
         <Welcome />
         <FrontServices />

         <FrontAppointment />
         <FrontProblems />
         <Map height={500} />
      </Layout>
   )
}

export default IndexPage

export function Head() {
   return (
      <title>Σαρδαρίδης Φιλάρετος Ανδρολόγος Ουρολόγος</title>
   )
}