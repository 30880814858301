import * as React from 'react'

export const services = [
   {
      id: '1',
      title: 'Ετήσιο Check Up Προστάτη',
      description:
         'Το ετήσιο check up του Προστάτη είναι μία προηγμένη επισκόπηση που γίνεται για να ελέγχεται η υγεία και η καλή λειτουργία του προστάτη.',
   },
   {
      id: '2',
      title: 'Διερεύνηση Αιματουρίας',
      description:
         'Η διερεύνηση αιματουρίας είναι μια διάγνωση που χρησιμοποιείται για να εντοπίσει μια αιματουργία, ή πάθηση στην κύστη.',
   },
   {
      id: '3',
      title: 'Ουροροομετρία',
      description:
         'Η ουροροομετρία είναι μια μέθοδος μελέτης των ποσότητών των ουρών που φέρονται από ένα άτομο και μπορεί να χρησιμοποιηθεί για την διάγνωση ορισμένων παθήσεων.',
   },
   {
      id: '4',
      title: 'Διαγνωστικοί Υπέρηχοι',
      description:
         'Μια ανώδυνη εξέταση που μπορεί καταγράψει την κατάσταση των νεφρών, της κύστεως, του προστάτη και του οσχέου και να βοηθήσει στην επιλογή των κατάλληλων θεραπειών.',
   },

   {
      id: '5',
      title: 'Triplex οσχέου',
      description:
         'Η προσέγγιση triplex επιτρέπει την ανάλυση της περιοχής του οσχέου με τριπλή διάχυτη εικόνα, επιτρέποντας λεπτομερές και ακριβές αποτέλεσμα.',
   },
   {
      id: '6',
      title: 'Κυστεοσκόπηση',
      description:
      'Η εύκαμπτη κυστεοσκόπηση είναι ανώδυνη και μπορεί να πραγματοποιηθεί στο χώρο του ιατρείου.',
   },
   {
      id: '7',
      title: 'Έλεγχος Γονιμότητας',
      description: 'Διαγνωστικές εξετάσεις για να εντωπιστούν τα αίτια της υπογονημότητας και να σχεδιαστεί ανάλογη αντιμετώπιση.',
   },
   {
      id: '8',
      title: 'Ουροδυναμικός Έλεγχος',
      description: 'Ο ουροδυναμικός έλεγχος είναι μία παρακλινική εξέταση που χρησιμεύει στη λειτουργική αξιολόγηση του κατώτερου ουροποιητικού.',
   },
   {
      id: '9',
      title: 'Πλήρης Κλινική Εξέταση ',
      description: 'Κλινική εξέταση και διάγνωση όλων τών παθήσεων του ουροποιητικού σε άνδρες και γυναίκες.'
   },
]

function FrontServices() {
   return (
      <div className="max-w-7xl mx-auto pt-28 pb-44" id="services">
         <h2 className="text-cyan-900 text-5xl font-light text-center mb-16">
            Υπηρεσίες Ιατρείου
         </h2>
         <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:p-0 p-6">
            {services.map((item, index) => {
               return (
                  <div key={index}>
                     <div className="bg-white rounded-lg px-6 py-8 h-full ring-1 ring-slate-900/5">
                        <div className="flex items-stretch justify-start">
                           <div>
                              <span className="inline-flex items-center justify-center p-2 bg-orange-400 rounded-md shadow-lg">
                                 <svg
                                    className="w-4 h-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                 >
                                    <path d="M512 0H64C28.65 0 0 28.65 0 64v288c0 35.35 28.65 64 64 64h149.7l-19.2 64H144C135.2 480 128 487.2 128 496S135.2 512 144 512h288c8.836 0 16-7.164 16-16S440.8 480 432 480h-50.49l-19.2-64H512c35.35 0 64-28.65 64-64V64C576 28.65 547.3 0 512 0zM227.9 480l19.2-64h81.79l19.2 64H227.9zM544 352c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h448c17.64 0 32 14.36 32 32V352zM368 176H320V128c0-8.838-7.164-16-16-16h-32C263.2 112 256 119.2 256 128v48H208C199.2 176 192 183.2 192 191.1V224c0 8.836 7.164 15.1 16 15.1H256v48C256 296.8 263.2 304 272 304h32c8.836 0 16-7.165 16-16v-48h48C376.8 239.1 384 232.8 384 224V191.1C384 183.2 376.8 176 368 176z" />
                                 </svg>
                              </span>
                           </div>
                           <div className="ml-4">
                              <h3 className="text-cyan-900 text-base font-medium tracking-tight">
                                 {item.title}
                              </h3>
                              <p className="text-slate-500 mt-2 text-sm">
                                 {item.description}
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               )
            })}
         </div>
      </div>
   )
}

export default FrontServices
