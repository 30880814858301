import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

function Hero() {
   return (
      <div className="relative">
         <StaticImage
            layout="fullWidth"
            src="../images/hero.jpg"
            alt="Photo of the doctor"
            quality={100}
            placeholder="blurred"
            className="md:h-[80vh]"
         />
         <div className="bg-gradient-to-b from-white/100 via-yellow-100/0 to-yellow-100/0 z-50 absolute inset-x-0 top-0 md:h-96 h-8" />
      </div>
   )
}

export default Hero
