import * as React from 'react'

import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

function frontAppointment() {
   return (
      <div className="pt-36 hidden">
         <div className="relative">
            <StaticImage
               src="../images/calendar.jpg"
               alt="Photo of the doctor."
               placeholder="blurred"
               quality={100}
               className="z-20"
            />
            <div className="flex flex-col items-center p-8 w-full absolute bottom-0 z-50 mb-36">
               <p className="text-white text-xl font-light mb-8">
                  Μπορείτε να μας επισκεφθείτε για οποιαδήποτε ουρολογική
                  εξέταση.
               </p>
               <Link
                  to="/epikoinonia"
                  className="hover:bg-white focus:outline-none border border-white rounded-full hover:text-cyan-700 text-white font-semibold h-12 px-6 w-full flex items-center justify-center sm:w-auto"
               >
                  Κλείστε Ραντεβού
               </Link>
            </div>
            <div className="bg-cyan-900/50 w-5 h-5/6 absolute top-0 -left-3 z-10 rounded-xl mt-12"></div>
         </div>
      </div>
   )
}

export default frontAppointment
