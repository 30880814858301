import * as React from 'react'

// Slider components
import { Swiper, SwiperSlide } from 'swiper/react'
import '../styles/slider.css'

export const diseases = [
   {
      id: '1',
      title: 'Διαστολές Ουρήθρας',
      description:
         'Διεύρυνση στενωμένου ουρηθρικού αυλού με ειδικούς μαλακούς καθετήρες.',
   },
   {
      id: '2',
      title: 'Εκτομή Βραχέως Χαλινού',
      description:'Πλαστική αποκατάσταση βραχέως χαλινού με τοπική αναισθησία.',
   },
   {
      id: '3',
      title: 'Αφαίρεση Κονδυλωμάτων',
      description: 'Η αφαίρεση κονδυλωμάτων με laser είναι μια αποτελεσματική και ασφαλής μέθοδος εξάλειψης των κονδυλωμάτων.',
   },
   {
      id: '4',
      title: 'Ενδοσκοπική Λιθοτριψία',
      description: 'Eίναι μια τεχνική χειρουργική επέμβαση που χρησιμοποιείται για την αφαίρεση λιθών από τον νεφρό.',
   },
   {
      id: '5',
      title: 'Turis Προστατεκτομή',
      description: 'Σύγχρονη χειρουργική επέμβαση για την αφαίρεση του προστάτη.',
   },
   {
      id: '6',
      title: 'Διουρηθρική εκτομή όγκου κύστης (TURBT)',
      description: 'Χειρουργική αφαίρεση ογκού κύστης που επιτυγχάνεται μέσω ενός διουρηθρικού συνδετικού σωλήνα, χρησιμοποιείται σε πρώιμα στάδια του καρκίνου της κύστης.',
   },
]

const frontProblems = () => {
   return (
      <div className="pt-36 pb-36 bg-cyan-800 overflow-hidden" id='surgeries'>
         <div className="mx-auto max-w-7xl px-8">
            <h3 className="text-2xl md:text-5xl text-white font-thin w-[60rem] max-w-full mb-12">
               Τα χειρουργεία γίνονται{' '}
               <span className="text-cyan-500">
                  ενδοσκοπικά, λαπαροσκοπικά,
               </span>{' '}
               ανοιχτά και πάντα επί ενδείξεων.
            </h3>
            <Swiper
               spaceBetween={50}
               slidesPerView={2}
               breakpoints={{
                  640: {
                     slidesPerView: 2,
                     spaceBetween: 20,
                  },
                  768: {
                     slidesPerView: 4,
                     spaceBetween: 40,
                  },
                  1024: {
                     slidesPerView: 4,
                     spaceBetween: 100,
                  },
               }}
            >
               <>
                  {diseases.map((item, index) => {
                     return (
                        <SwiperSlide>
                           <div key={index}>
                              <div className="text-white mb-2">
                                 {item.title}
                              </div>
                              <div className="text-cyan-100">
                                 {item.description}
                              </div>
                           </div>
                        </SwiperSlide>
                     )
                  })}
               </>
            </Swiper>
         </div>
      </div>
   )
}

export default frontProblems
